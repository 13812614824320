<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container">
        <header class="admin-header">
          <h1>Einstellungen</h1>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <div v-if="loading">
            <div class="loading-wrap">
              <div class="loading-spinner"></div>
            </div>
          </div>
          <div v-else>

            <div v-for="admin_setting in admin_settings" v-bind:key="admin_setting.id" class="row row-gutter-20">
              <div class="col-8">
                <p style="text-align: right;margin-top: 30px;font-size: 16px;font-weight: 600">{{ admin_setting.name }}</p>
              </div>
              <div class="col-8">
                <p class="form-wrap">
                  <input v-model="admin_setting.body" type="text" class="form-input">
                </p>
              </div>
              <div class="col-8">
                <p class="form-wrap">
                  <a @click="update_setting(admin_setting)" class="button button-yellow button-round button-100" style="padding:0.9rem 2rem">Speichern</a>
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import AdminNav from "@/components/admin_nav";
export default {
  name: "settings",
  components: {
    AdminNav,
  },
  data() {
    return {
      search_phrase: "",
      admin_settings: [],
      loading: true,
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/settings", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.admin_settings = response.data.settings;
          this.loading = false;
        });
    },
    update_setting(admin_setting) {
      axios
        .patch(process.env.VUE_APP_BASE_API + "/admin/settings/" + admin_setting.id, {
          body: admin_setting.body
        }, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then(() => {
          this.$notify({
            group: "notification",
            type: "success",
            text: "Erfolgreich gespeichert",
          });
          this.get_data();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    }
  },
  mounted() {
    this.get_data();
  },
};
</script>
